.valuePropositionSection {
    display: flex;
    width: 100%;
    max-width: 1085px;
    gap: 20px;
    margin: 149px auto 0;
  }
  
  @media (max-width: 991px) {
    .valuePropositionSection {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
      max-width: 100%;
      margin-top: 40px;
    }
  }