.hero {
    display: flex;
    width: 100%;
    max-width: 1212px;
    margin: 42px 0 0 53px;
  }
  
  .decorativeImage {
    aspect-ratio: 0.99;
    object-fit: contain;
    width: 130px;
    align-self: flex-start;
    margin-top: 338px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    max-width: 845px;
  }
  
  .title {
    font-size: 64px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: -0.16px;
    font-family: Fahkwang, sans-serif;
    color: rgba(20, 20, 19, 1);
  }
  
  .description {
    font-size: 24px;
    font-weight: 500;
    line-height: 37px;
    margin-top: 34px;
    font-family: Fahkwang, sans-serif;
    color: rgba(20, 20, 19, 1);
  }
  
  .ctaButton {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: rgba(204, 120, 92, 1);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    margin-top: 37px;
    color: rgba(255, 255, 255, 1);
    padding: 18px 23px;
    font: 600 17px/1 Inter, sans-serif;
    border: 1px solid rgba(112, 107, 87, 0.25);
  }
  
  .illustration {
    aspect-ratio: 1.01;
    object-fit: contain;
    width: 120px;
    margin-top: -500px;
  }
  
  @media (max-width: 991px) {
    .hero {
      max-width: 100%;
      margin-top: 40px;
      flex-direction: column;
    }
  
    .decorativeImage {
      margin-top: 40px;
    }
  
    .title {
      font-size: 40px;
      line-height: 55px;
    }
  
    .description {
      margin-right: 10px;
    }
  
    .illustration {
      margin-top: 40px;
    }
  
    .ctaButton {
      padding: 0 20px;
    }
  }