.mainContent {
    border-radius: 12px;
    background-color: rgba(240, 239, 234, 1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  @media (max-width: 991px) {
    .mainContent {
      max-width: 100%;
    }
  }