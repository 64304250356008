.header {
    background-color: rgba(240, 239, 234, 1);
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 55px;
    color: rgba(20, 20, 19, 1);
  }
  
  .logo {
    font: 600 30px/3 'Encode Sans Expanded', -apple-system, Roboto, Helvetica, sans-serif;
    letter-spacing: -1.28px;
  }
  
  .badge {
    border-radius: 99px;
    background-color: rgba(255, 255, 255, 1);
    padding: 4px 14px;
    font: 400 16px/2 Inter, sans-serif;
    letter-spacing: -0.28px;
    margin-right: 100px;
  }
  
  .badgeHighlight {
    font-family: 'Encode Sans Expanded', sans-serif;
    font-weight: 600;
    color: rgba(20, 20, 19, 1);
  }
  
  @media (max-width: 991px) {
    .header {
      padding: 0 20px;
    }
  }