.card {
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    gap: 20px;
    font-family: 'Rethink Sans', -apple-system, Roboto, Helvetica, sans-serif;
    letter-spacing: -0.54px;
    justify-content: space-between;
    padding: 47px 0 47px 64px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .header {
    display: flex;
    gap: 10px;
    font-size: 32px;
    line-height: 1;
  }
  
  .tag {
    border-radius: 4px;
    background-color: rgba(204, 120, 92, 1);
    color: rgba(255, 255, 255, 1);
    padding: 11px;
  }
  
  .title {
    color: rgba(101, 99, 88, 1);
    text-align: center;
    margin: auto 0;
  }
  
  .description {
    color: rgba(101, 99, 88, 1);
    font-size: 17px;
    line-height: 25px;
    align-self: stretch;
    margin-top: 17px;
  }
  
  .image {
    aspect-ratio: 1.14;
    object-fit: contain;
    width: 58px;
    border-radius: 4px;
    margin: auto 0;
  }
  
  @media (max-width: 991px) {
    .card {
      max-width: 100%;
      padding-left: 20px;
      margin-top: 40px;
    }
  
    .tag {
      white-space: normal;
    }
  
    .description {
      max-width: 100%;
    }
  }