.valueProposition {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
    letter-spacing: -0.54px;
  }
  
  .image {
    aspect-ratio: 1.39;
    object-fit: contain;
    width: 244px;
    max-width: 100%;
  }
  
  .title {
    color: rgba(20, 20, 19, 1);
    margin-top: 33px;
    font: 600 24px/1 Fahkwang, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .description {
    color: rgba(101, 99, 88, 1);
    align-self: stretch;
    margin-top: 35px;
    font: 400 20px/25px 'Rethink Sans', -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  @media (max-width: 991px) {
    .valueProposition {
      max-width: 100%;
      margin-top: 40px;
    }
  
    .description {
      max-width: 100%;
    }
  }