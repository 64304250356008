.tagline {
    display: flex;
    margin-top: 198px;
    width: 716px;
    max-width: 100%;
    flex-direction: column;
    font-family: Fahkwang, sans-serif;
    font-weight: 500;
  }
  
  .title {
    color: rgba(0, 0, 0, 1);
    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    align-self: center;
    width: 521px;
  }
  
  .description {
    color: rgba(0, 0, 0, 1);
    font-size: 27px;
    line-height: 37px;
    text-align: center;
    align-self: flex-start;
    margin-top: 31px;
  }
  
  @media (max-width: 991px) {
    .tagline {
      margin-top: 40px;
    }
  
    .title {
      max-width: 100%;
      font-size: 40px;
      line-height: 56px;
    }
  
    .description {
      max-width: 100%;
    }
  }