.footer {
    border-radius: 0 0 12px 12px;
    background-color: rgba(20, 20, 19, 1);
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 48px 64px 24px;
  }
  
  .content {
    display: flex;
    width: 100%;
    max-width: 1076px;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .logo {
    color: rgba(240, 239, 234, 1);
    letter-spacing: -1.28px;
    font: 600 30px/3 'Encode Sans Expanded', -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .navigation {
    display: flex;
    height: 154px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 32px;
    color: rgba(163, 162, 153, 1);
    justify-content: flex-start;
    flex-wrap: wrap;
    font: 400 17px/1 Inter, sans-serif;
  }
  
  .column {
    display: flex;
    min-height: 330px;
    flex-direction: column;
    white-space: nowrap;
    letter-spacing: -0.16px;
    justify-content: flex-start;
    flex-grow: 1;
    width: 152px;
    padding: 0 104px 1985px 0;
    list-style-type: none;
  }
  
  .column li {
    margin-top: 16px;
  }
  
  .column li:first-child {
    margin-top: 0;
  }
  
  .copyright {
    color: rgba(163, 162, 153, 1);
    letter-spacing: -0.16px;
    align-self: center;
    margin-top: 70px;
    font: 400 17px/1.2 Inter, sans-serif;
  }
  
  @media (max-width: 991px) {
    .footer {
      max-width: 100%;
      padding: 0 20px;
    }
  
    .content {
      max-width: 100%;
    }
  
    .navigation {
      max-width: 100%;
    }
  
    .column {
      padding-bottom: 100px;
      white-space: normal;
    }
  
    .copyright {
      max-width: 100%;
      margin-top: 40px;
    }
  }