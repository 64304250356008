.cta {
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    width: 884px;
    max-width: 100%;
    flex-direction: column;
    margin: 174px auto 0;
    padding: 0 25px 48px 67px;
  }
  
  .title {
    border-radius: 4px;
    background-color: rgba(204, 120, 92, 1);
    color: rgba(255, 255, 255, 1);
    letter-spacing: -0.54px;
    width: 100%;
    padding: 11px 22px;
    font: 500 32px/1 'Rethink Sans', -apple-system, Roboto, Helvetica, sans-serif;
    margin-top: 95px;
  }
  
  .image {
    aspect-ratio: 1.01;
    object-fit: contain;
    width: 120px;
    align-self: flex-end;
  }
  
  .description {
    color: rgba(101, 99, 88, 1);
    font-weight: 400;
    line-height: 25px;
    margin-top: 33px;
    font: 20px 'Rethink Sans', sans-serif;
  }
  
  .ctaButton {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: rgba(204, 120, 92, 1);
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    overflow: hidden;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    line-height: 1;
    padding: 17px 31px;
    border: 1px solid rgba(112, 107, 87, 0.25);
    align-self: flex-start;
    margin-top: 20px;
  }
  
  @media (max-width: 991px) {
    .cta {
      max-width: 100%;
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .title {
      max-width: 100%;
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .image {
      margin-top: 40px;
    }
  
    .description {
      max-width: 100%;
    }
  
    .ctaButton {
      padding: 0 20px;
    }
  }