.joinWaitlist {
    border-radius: 12px;
    background-color: #f0efea;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .header {
    background-color: #f0efea;
    width: 100%;
    color: #141413;
    white-space: nowrap;
    letter-spacing: -1.28px;
    padding: 22px 69px;
    font: 600 30px/3 'Encode Sans Expanded', -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .heroSection {
    align-self: center;
    width: 780px;
    max-width: 100%;
    margin: 85px 0 0 82px;
  }
  
  .heroContent {
    display: flex;
    gap: 20px;
  }
  
  .heroTextContent {
    display: flex;
    margin-top: 39px;
    gap: 36px;
    color: #141413;
    flex-wrap: wrap;
  }
  
  .heroTitle {
    letter-spacing: -0.16px;
    flex-grow: 1;
    flex-basis: auto;
    font: 700 96px/1 Fahkwang, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .productBadge {
    border-radius: 99px;
    background-color: #fff;
    align-self: flex-end;
    margin-top: 35px;
    overflow: hidden;
    letter-spacing: -0.28px;
    padding: 4px 14px;
    font: 400 16px/2 Inter, sans-serif;
  }
  
  .companyName {
    font-family: 'Encode Sans Expanded', sans-serif;
    font-weight: 600;
    color: #141413;
  }
  
  .heroImage {
    aspect-ratio: 1.01;
    object-fit: contain;
    object-position: center;
    width: 120px;
    max-width: 100%;
    flex-grow: 1;
  }
  
  .subHeroSection {
    align-self: center;
    margin-top: 26px;
    width: 879px;
    max-width: 100%;
    display: flex;
    gap: 20px;
  }
  
  .subHeroImage {
    aspect-ratio: 0.99;
    object-fit: contain;
    object-position: center;
    width: 130px;
    max-width: 100%;
    align-self: stretch;
    margin: auto 0;
  }
  
  .subHeroContent {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  
  .subHeroTitle {
    color: #000;
    letter-spacing: -0.16px;
    margin: 0 63px 0 41px;
    font: 600 64px/1 Fahkwang, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .subHeroDescription {
    color: #141413;
    text-align: center;
    align-self: center;
    margin-top: 43px;
    font: 500 27px/37px Fahkwang, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .waitlistFormSection {
    border-radius: 24px;
    background-color: #fff;
    display: flex;
    margin-top: 34px;
    width: 100%;
    flex-direction: column;
    padding: 39px 64px;
  }
  
  .formHeader {
    align-self: flex-start;
    display: flex;
    gap: 11px;
    font-size: 32px;
    font-weight: 500;
  }
  
  .formTag {
    border-radius: 4px;
    background-color: #cc785c;
    font-family: Inter, sans-serif;
    color: #fff;
    white-space: nowrap;
    letter-spacing: 1px;
    line-height: 1;
    padding: 10px 8px;
  }
  
  .formTitle {
    color: #656358;
    font-family: 'Rethink Sans', -apple-system, Roboto, Helvetica, sans-serif;
    line-height: 1;
    letter-spacing: -0.54px;
    text-align: center;
    flex-basis: auto;
    flex-grow: 1;
    margin: auto 0;
  }
  
  .waitlistForm {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 33px;
  }
  
  .formInputGroup {
    display: flex;
    gap: 11px;
    color: rgba(41, 38, 27, 0.5);
    flex-wrap: wrap;
    font: 400 16px/2 Inter, sans-serif;
  }
  
  .formInput {
    border-radius: 10px;
    background-color: #f8f8f7;
    padding: 12px;
    border: 1px solid rgba(112, 107, 87, 0.5);
  }
  
  .formSubmitGroup {
    display: flex;
    gap: 10px;
    font-family: Inter, sans-serif;
    flex-wrap: wrap;
  }
  
  .submitButton {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #cc785c;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    overflow: hidden;
    font-size: 17px;
    color: #fff;
    font-weight: 500;
    line-height: 1;
    padding: 12px 19px;
    border: 1px solid rgba(112, 107, 87, 0.25);
    cursor: pointer;
  }
  
  .footer {
    border-radius: 0 0 12px 12px;
    background-color: #141413;
    display: flex;
    margin-top: 86px;
    width: 100%;
    flex-direction: column;
    padding: 48px 64px 24px;
  }
  
  .footerContent {
    display: flex;
    width: 100%;
    max-width: 1076px;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footerLogo {
    color: #f0efea;
    letter-spacing: -1.28px;
    align-self: flex-start;
    font: 600 30px/3 'Encode Sans Expanded', -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .footerNav {
    display: flex;
    height: 154px;
    padding-bottom: 16px;
    align-items: flex-start;
    gap: 32px;
    color: #a3a299;
    justify-content: flex-start;
    flex-wrap: wrap;
    font: 400 17px/1 Inter, sans-serif;
  }
  
  .footerColumn {
    display: flex;
    min-height: 330px;
    flex-direction: column;
    white-space: nowrap;
    letter-spacing: -0.16px;
    justify-content: flex-start;
    flex-grow: 1;
    width: 152px;
    padding: 0 104px 1985px 0;
    list-style-type: none;
  }
  
  .footerColumn li {
    margin-top: 16px;
  }
  
  .footerColumn li:first-child {
    margin-top: 0;
  }
  
  .copyright {
    color: #a3a299;
    letter-spacing: -0.16px;
    align-self: center;
    margin-top: 70px;
    font: 400 17px/1.2 Inter, sans-serif;
  }
  
  .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  @media (max-width: 991px) {
    .header {
      max-width: 100%;
      white-space: initial;
      padding: 0 20px;
    }
  
    .heroSection {
      margin-top: 40px;
    }
  
    .heroContent {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  
    .heroTitle {
      font-size: 40px;
    }
  
    .heroImage {
      margin-top: 40px;
    }
  
    .subHeroSection {
      flex-direction: column;
      align-items: stretch;
      gap: 0;
    }
  
    .subHeroImage {
      margin-top: 40px;
    }
  
    .subHeroContent {
      max-width: 100%;
      margin-top: 40px;
    }
  
    .subHeroTitle {
      max-width: 100%;
      margin-right: 10px;
      font-size: 40px;
    }
  
    .subHeroDescription {
      max-width: 100%;
      margin-top: 40px;
    }
  
    .waitlistFormSection {
      max-width: 100%;
      padding: 0 20px;
    }
  
    .formTag {
      white-space: initial;
    }
  
    .formInput {
      padding-right: 20px;
    }
  
    .footer {
      max-width: 100%;
      margin-top: 40px;
      padding: 0 20px;
    }
  
    .footerContent {
      max-width: 100%;
    }
  
    .footerColumn {
      padding-bottom: 100px;
      white-space: initial;
    }
    .copyright {
    max-width: 100%;
    margin-top: 40px;
  }
  }
  /* ... (previous styles remain unchanged) */
  
  .errorMessage {
    color: #d32f2f;
    font-size: 0.875rem;
    margin-top: 4px;
  }
  
  .formInput[aria-invalid="true"] {
    border-color: #d32f2f;
  }
  
  .submitButton:hover {
    background-color: #b35a3f;
  }
  
  .submitButton:focus {
    outline: 2px solid #f0efea;
    outline-offset: 2px;
  }
  
  /* Ensure form elements are large enough for touch targets */
  .formInput,
  .submitButton {
    min-height: 44px;
  }
  
  /* Improve contrast for placeholder text */
  .formInput::placeholder {
    color: #656358;
  }
  
  /* Add focus styles for better keyboard navigation */
  .formInput:focus,
  select:focus {
    outline: 2px solid #cc785c;
    outline-offset: 2px;
  }
  
  /* Ensure text remains visible during webfont load */
  .heroTitle,
  .subHeroTitle,
  .formTitle {
    font-display: swap;
  }
  
  /* Optimize for print */
  @media print {
    .joinWaitlist {
      background-color: #fff;
    }
  
    .submitButton {
      display: none;
    }
  
    .footer {
      border-top: 1px solid #000;
      background-color: #fff;
      color: #000;
    }
  }
  
  /* Reduce motion for users who prefer it */
  @media (prefers-reduced-motion: reduce) {
    * {
      animation-duration: 0.01ms !important;
      animation-iteration-count: 1 !important;
      transition-duration: 0.01ms !important;
      scroll-behavior: auto !important;
    }
  }